<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <div class="flex flex-row justify-center w-full mt-6">
    <router-link
      class="w-1/2 p-2 mx-2 text-black bg-white rounded-md focus:outline-none"
      to="/user/signin"
    >
      Sign in
    </router-link>
    <router-link
      class="
        w-1/2
        p-2
        mx-2
        text-white
        rounded-md
        bg-qgo-primary-bg
        focus:outline-none
      "
      to="/user/signup"
    >
      Register
    </router-link>
  </div>
  <div class="flex flex-col w-full mt-6">
    <button
      class="w-full p-2 m-1 text-white bg-black rounded-md focus:outline-none"
    >
      Sign in with Apple
    </button>
    <!-- <button
      class="
        w-full
        p-2
        m-1
        text-white
        bg-blue-500
        rounded-md
        focus:outline-none
      "
    >
      Sign in with Facebook
    </button> -->
    <v-facebook-login
      @login="onFaceBookLogin"
      :app-id="appID"
      @sdk-init="handleSdkInit"
      v-model="model"
    ></v-facebook-login>
    <button
      @click="handleClickSignIn"
      class="w-full p-2 m-1 text-white bg-red-500 rounded-md focus:outline-none"
    >
      Sign in with Google
    </button>
  </div>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component-next";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      appID: process.env.VUE_APP_FACEBOOK_APP_ID,
      isLoading: false,
      FB: {},
      model: {},
      scope: {},
    };
  },
  components: {
    VFacebookLogin,
    Loading,
  },
  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        let requestData = {
          social_id: googleUser.getBasicProfile().getId(),
          social_type: "google",
          email: googleUser.getBasicProfile().getEmail(),
          name: googleUser.getBasicProfile().getName(),
        };
        // this.user = googleUser.getBasicProfile().getId();

        // this.user = googleUser.getBasicProfile().getEmail();
        // console.log("getId", this.user);
        // console.log("get useremail",googleUser.getBasicProfile().getEmail());
        // console.log("get name",googleUser.getBasicProfile().getName());
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        // console.log("getAuthResponse", googleUser.getAuthResponse());
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );
        if (requestData.social_id) {
          this.isLoading = true;
          this.$http
            .post("auth/sociallogin", requestData)
            .then((response) => {
              let data = {
                id: response.data.user.id,
                email: response.data.user.email,
                isLoggedIn: true,
                access_token: response.data.access_token,
                role: 1,
                name: response.data.user.name,
                isSocialLogin: true,
                socialType: "google",
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$router.push({ name: "UserHome" });
              this.isLoading = false;
            })
            .catch((error) => {
              let msg = "Something went wrong";
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                msg = error.response.data.message;
              }
              this.$swal.fire({
                icon: "error",
                title: msg,
                timer: 8000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              this.isLoading = false;
            });
        }
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    onFaceBookLogin(data) {
      if (data) {
        this.isLoading = true;
        if (data.authResponse && data.authResponse.userID) {
          this.$http
            .post("auth/sociallogin", {
              social_id: data.authResponse.userID,
              social_type: "facebook",
            })
            .then((response) => {
              let data = {
                id: response.data.user.id,
                email: response.data.user.email,
                isLoggedIn: true,
                access_token: response.data.access_token,
                role: 1,
                name: response.data.user.name,
                isSocialLogin: true,
                socialType: "facebook",
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$router.push({ name: "UserHome" });
              this.isLoading = false;
            })
            .catch((error) => {
              let msg = "Something went wrong";
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                msg = error.response.data.message;
              }
              this.$swal.fire({
                icon: "error",
                title: msg,
                timer: 8000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              this.isLoading = false;
            });
        }
      } else {
        if (this.scope.logout) {
          this.scope.logout();
        }
      }
    },
    initApple() {
      window.AppleID.auth.init({
        clientId: "com.example.signin", // This is the service ID we created.
        scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: "http://example.com", // As registered along with our service ID
        state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
        usePopup: true, // Important if we want to capture the data apple sends on the client side.
      });
    },

    async signInWithApple() {
      try {
        const response = await window.AppleID.auth.signIn();
        console.log(response);
      } catch (error) {
        console.log(error);
      }
      
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },
  },
  mounted() {
    this.initApple();
  },
  unmounted() {},
};
</script>
<style  lang="scss">
.v-facebook-login {
  background: #3b82f6;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin: 0.25rem;
  width: 100%;
  color: #fff;
  .logo {
    // display: inline;
    display: none;
  }
}
</style>
