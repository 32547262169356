<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div
    class="flex flex-col items-center justify-center h-screen bg-qgo-primary-bg"
  >
    <div class="flex flex-col items-center justify-center w-2/3 h-screen mt-5">
      <div class="flex flex-row items-center justify-center w-full pt-5">
        <div class="">
          <img
            src="@/assets/img/Q&GO_Vert_GreyWhite.png"
            alt=""
            class="object-scale-down w-52"
          />
        </div>
        <div class="px-2 text-3xl text-white"></div>
      </div>
      <form autocomplete="off">
        <div class="pt-6 mx-4 w-72">
          <div class="flex flex-col justify-center w-full">
            <input
              name="username"
              type="email"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
                input
              "
              placeholder="Email"
              v-model="input.email"
              required
              @input="validateEmail"
              @blur="changeBlurStatus"
              v-on:keyup.enter="resetPassword"
            />
            <p class="pt-2 text-red-600" v-if="!emailValid">{{ errorMessage }}</p>
          </div>
        </div>
        <div class="justify-center pt-6 mx-4 w-72">
          <button
            id="loginButton"
            type="button"
            class="
              w-full
              p-2
              my-1
              mt-5
              text-black
              bg-white
              rounded-md
              focus:outline-none
            "
            @click="resetPassword"
          >
            Reset password
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex items-baseline justify-end w-screen h-20 bg-qgo-secondary-bg"
    >
      <img
        src="@/assets/img/LoginLogo.png"
        alt=""
        class="float-right w-16 h-16 mt-1 mr-5"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  components: {
    Loading,
  },
  data() {
    return {
      errorMsg: "",
      isLoading: false,
      fullPage: true,
      loader: "bars",
      input: {
        email: "",
      },
      errorMessage: "",
      emailValid: true,
      isEmailBlur: false,
    };
  },
  methods: {
    changeBlurStatus() {
      this.isEmailBlur = true;
      this.validateEmail();
    },
    validateEmail() {
      if (this.isEmailBlur) {
        if (this.input.email) {
          if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              this.input.email
            )
          ) {
            this.emailValid = true;
            this.errorMessage = '';
          } else {
            this.emailValid = false;
            this.errorMessage = 'Please enter a valid email';
          }
        } else {
          this.emailValid = false;
          this.errorMessage = 'Email is required'
        }
      }
    },
    resetPassword() {
      this.changeBlurStatus();
      console.log(this.emailValid);
      console.log(this.input);
      if (this.input.email && this.emailValid) {
        this.isLoading = true;
        this.$http
          .post("auth/forgot/password", { email: this.input.email })
          .then((res) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              title: res.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            // this.$router.push({ name: "Home" });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: err.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
  },
});
</script>

<style scoped>
.input {
  color: #fff;
  border-color: #fff;
}
.input::placeholder {
  color: #fff;
}
</style>