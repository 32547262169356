<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <user-index class="flex items-center justify-center h-full">
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="
          flex flex-col
          h-full
          p-2
          place-content-between
          x-sm:w-full
          sm:w-2/3
          lg:w-1/3
        "
      >
        <div class="flex justify-center p-4">
          <div class="flex justify-between w-full">
            <transition name="slide-logo" mode="in-out">
              <img
                v-show="logo_transition"
                class="object-scale-down h-20"
                src="../../../assets/img/LoginLogo.png"
              />
            </transition>
            <div class="flex flex-col items-end justify-end">
              <p
                class="text-2xl font-bold text-qgo-primary-bg justify-self-end"
              >
                SIGN IN
              </p>
              <p class="text-xl font-bold text-white justify-self-end">
                WELCOME BACK
              </p>
            </div>
          </div>
        </div>
        <div class="p-1 mx-4">
          <div class="flex flex-col justify-center w-full">
            <input
              name="signinUserDetail"
              type="text"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Email or Phone Number"
              v-model="input.email"
              @blur="onBlur('email')"
              @input="emailVerification"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.email"
            >
              {{ errors.email }}
            </p>
            <input
              name="signinUserPassword"
              type="password"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b-2
                text-white
                border-gray-600
                focus:ring-0 focus:border-qgo-primary-bg
                bg-qgo-secondary-bg
              "
              placeholder="Password"
              v-model="input.password"
              @blur="onBlur('password')"
              @input="passwordVerification"
              v-on:keyup.enter="signIn"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.password"
            >
              {{ errors.password }}
            </p>
          </div>
        </div>
        <div class="p-1">
          <div class="flex flex-col w-full mb-10">
            <router-link
              class="p-2 mx-4 my-1 text-white rounded-md focus:outline-none"
              to="/forgot-password"
            >
              Forgot password
            </router-link>
            <button
              class="
                p-2
                mx-4
                my-1
                text-white
                rounded-md
                bg-qgo-primary-bg
                focus:outline-none
              "
              @click="signIn"
            >
              Sign In
            </button>
            <router-link
              class="
                p-2
                mx-4
                my-1
                text-black
                bg-white
                rounded-md
                focus:outline-none
              "
              to="/user"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import UserIndex from "@/views/User/Index";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      logo_transition: false,
      input: {
        email: "",
        password: ""
      },
      errors: {
        email: "",
        password: ""
      },
      validStatus: {
        email: "",
        password: ""
      },
      blurStatus: {
        email: "",
        password: ""
      }
    };
  },
  components: {
    UserIndex,
    Loading
  },
  methods: {
    signIn() {
      this.onBlur("password");
      this.onBlur("email");
      if (this.validStatus.email && this.validStatus.password) {
        this.isLoading = true;
        let req = {
          email: this.input.email,
          password: this.input.password
        };
        this.$http
          .post("auth/login", req)
          .then(response => {
            if (response.data.user.role != "vendor") {
              this.isLoading = false;
              let data = {
                id: response.data.user.id,
                email: response.data.user.email,
                isLoggedIn: true,
                access_token: response.data.access_token,
                role: 1,
                name: response.data.user.name,
                isSocialLogin: false,
                socialType: ""
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$router.push({ name: "UserHome" });
            } else {
              this.$swal.fire({
                icon: "error",
                title:
                  "Sorry, You are not a customer! Please sign up as a customer if you want to proceed.",
                timer: 8000,
                toast: true,
                position: "top-end",
                showConfirmButton: false
              });
              this.isLoading = false;
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === "Phone number is not verified"
            ) {
              sessionStorage.setItem("OTP_UserID", error.response.data.user.id);
              this.$router.push({ name: "OTPVerification" });
            }
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false
            });
          });
      }
    },
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else if (
            rgex.test(this.input.email) &&
            this.input.email.length > 9 &&
            this.input.email.length < 15
          ) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email or phone number";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email or Phone number is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    passwordVerification() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          this.errors.password = "";
          this.validStatus.password = true;
        } else {
          this.errors.password = "Password is required";
          this.validStatus.password = false;
        }
      } else {
        this.errors.password = "";
        this.validStatus.password = true;
      }
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "password":
          this.blurStatus.password = true;
          this.passwordVerification();
          break;
        default:
          console.log("fgh");
          break;
      }
    }
  },
  mounted() {
    this.logo_transition = true;
  }
};
</script>
