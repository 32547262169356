<template>
  <!-- <div
    class="absolute z-10 w-8 h-12 bg-gray-200 rounded-full -top-8 left-14"
  ></div>
  <div
    class="absolute w-12 h-16 bg-white border-b-2 rounded-full -top-10 left-12"
    :class="ready ? 'border-qgo-primary-bg' : 'border-green-900'"
  ></div> -->
  <div class="flex-row h-full px-1 pt-1 bg-white queue-card-shadow ">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ready: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  setup(props) {
    return { ...props };
  }
});
</script>
