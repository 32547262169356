<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <div
    class="flex flex-col items-center justify-center h-screen bg-qgo-primary-bg"
  >
    <div class="flex flex-col items-center justify-center w-2/3 h-screen mt-5">
      <div class="flex flex-row items-center justify-center w-full pt-5">
        <div class="">
          <img
            src="@/assets/img/Q&GO_Vert_GreyWhite.png"
            alt=""
            class="object-scale-down w-52"
          />
        </div>
        <div class="px-2 text-3xl text-white"></div>
      </div>
      <form autocomplete="off">
        <div class="pt-6 mx-4 w-72">
          <div class="flex flex-col justify-center w-full">
            <input
              name="username"
              type="text"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
                input
              "
              placeholder="OTP"
              v-model="input.email"
              required
              @input="validateOTP"
              @blur="changeBlurStatus"
              max="6"
              min="6"
              v-on:keyup.enter="submitOTP()"
            />
            <p class="pt-2 text-red-600" v-if="!emailValid">
              {{ errorMessage }}
            </p>
          </div>
        </div>
        <div class="justify-center pt-6 mx-4 w-72">
          <span v-if="!enableResendOTP">Resend OTP in ({{ count }})</span>
          <span @click="resendOTP()" v-else class="text-white resend-otp"
            >Resend OTP</span
          >
          <button
            id="loginButton"
            type="button"
            class="
              w-full
              p-2
              my-1
              mt-5
              text-black
              bg-white
              rounded-md
              focus:outline-none
            "
            @click="submitOTP()"
          >
            Verify
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex items-baseline justify-end w-screen h-20 bg-qgo-secondary-bg"
    >
      <img
        src="@/assets/img/LoginLogo.png"
        alt=""
        class="float-right w-16 h-16 mt-1 mr-5"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {},
  components: {
    Loading,
  },
  data() {
    return {
      count: 30,
      enableResendOTP: false,
      counter: null,
      userID: "",
      errorMsg: "",
      isLoading: false,
      input: {
        email: "",
      },
      errorMessage: "",
      emailValid: true,
      isEmailBlur: false,
    };
  },
  methods: {
    changeBlurStatus() {
      this.isEmailBlur = true;
      this.validateOTP();
    },
    validateOTP() {
      if (this.isEmailBlur) {
        if (this.input.email) {
          let n = this.input.email.toString();
          let regex = /^\d+$/;
          if (regex.test(n)) {
            if (n.length == 6) {
              this.emailValid = true;
              this.errorMessage = "";
            } else {
              this.emailValid = false;
              this.errorMessage = "OTP should be of 6 numbers";
            }
          } else {
            this.emailValid = false;
            this.errorMessage = "Invalid OTP(only digits are allowed)";
          }
        } else {
          this.emailValid = false;
          this.errorMessage = "OTP is required";
        }
      }
    },
    resendOTP() {
      this.isLoading = true;
      this.$http
        .post("auth/resend-otp", { user_id: this.userID })
        .then((response) => {
          if (response && response.data.status) {
            this.enableResendOTP = false;
            this.counter = setInterval(() => {
              this.timer();
            }, 1000);
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    submitOTP() {
      this.changeBlurStatus();
      if (this.emailValid) {
        this.isLoading = true;
        this.$http
          .post("auth/verify-otp", {
            user_id: this.userID,
            otp: this.input.email,
          })
          .then((response) => {
            if (response && response.data.status) {
              this.$swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              if (response.data.userData.role == "vendor") {
                if (!response.data.userData.last_login) {
                localStorage.setItem(
                  "last_login",
                  response.data.userData.last_login
                );
              }
              let data = {
                id: response.data.userData.id,
                email: response.data.userData.email,
                isLoggedIn: true,
                access_token: sessionStorage.getItem("acktoken"),
                role: 2,
                name: response.data.userData.name,
                logo: response.data.userData.profile_image,
                isSocialLogin: false,
                socialType: "",
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$store.commit("profile/setVendorLogo", response.data.userData.profile_image);
              this.$store.commit(
                "profile/setvendorName",
                response.data.userData.name
              );
              this.$router.push({ name: "VendorProfileQ" });
              } else {
                let data = {
                id: response.data.userData.id,
                email: response.data.userData.email,
                isLoggedIn: true,
                access_token: sessionStorage.getItem("acktoken"),
                role: 1,
                name: response.data.userData.name,
                isSocialLogin: false,
                socialType: "",
              };
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$router.push({ name: "UserHome" });
              }
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response.data);
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
    timer() {
      this.count -= 1;
      if (this.count <= 0) {
        clearInterval(this.counter);
        this.enableResendOTP = true;
        //counter ended, do something here
        return;
      }
    },
  },
  created() {
    let userID = sessionStorage.getItem("OTP_UserID");
    if (userID) {
      this.userID = parseInt(userID);
    } else {
      this.$swal.fire({
        icon: "error",
        title: "Something went wrong.",
        timer: 3000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
      this.$router.push({ name: "Home" });
    }
    this.counter = setInterval(() => {
      this.timer();
    }, 1000);
  },
});
</script>

<style scoped>
.input {
  color: #fff;
  border-color: #fff;
}
.input::placeholder {
  color: #fff;
}
.resend-otp {
  cursor: pointer;
}
</style>
