<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div
    class="flex flex-col items-center justify-center h-screen bg-qgo-primary-bg"
  >
    <div class="flex flex-col items-center justify-center w-2/3 h-screen mt-5">
      <div class="flex flex-row items-center justify-center w-full pt-5">
        <div class="">
          <img
            src="@/assets/img/Q&GO_Vert_GreyWhite.png"
            alt=""
            class="object-scale-down w-52"
          />
        </div>
        <div class="px-2 text-3xl text-white"></div>
      </div>
      <form autocomplete="off">
        <div class="pt-6 mx-4 w-72">
          <div class="flex flex-col justify-center w-full">
            <input
              name="username"
              type="password"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
                input
              "
              placeholder="Password"
              required
              @blur="onBlur('password')"
              v-model="input.password"
              @input="validatePassword"
            />
            <p class="pt-2 text-red-600" v-if="!validPassword">
              {{ errors.password }}
            </p>
            <input
              name="password"
              type="password"
              v-model="input.confirm_password"
              class="
                mt-2
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
                input
              "
              placeholder="Confirm password"
              required
              @blur="onBlur('confirm_password')"
              @input="validateConfirmPassword"
              v-on:keyup.enter="check"
            />
            <p class="pt-2 text-red-600" v-if="!validCPassword">
              {{ errors.confirm_password }}
            </p>
          </div>
        </div>
        <div class="justify-center pt-6 mx-4 w-72">
          <button
            id="loginButton"
            type="button"
            class="
              w-full
              p-2
              my-1
              mt-5
              text-black
              bg-white
              rounded-md
              focus:outline-none
            "
            @click="check"
          >
            Change password
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex items-baseline justify-end w-screen h-20 bg-qgo-secondary-bg"
    >
      <img
        src="@/assets/img/LoginLogo.png"
        alt=""
        class="float-right w-16 h-16 mt-1 mr-5"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

import Loading from "vue-loading-overlay";

export default defineComponent({
  data() {
    return {
      errorMsg: "",
      isLoading: false,
      fullPage: true,
      loader: "bars",
      input: {
        password: "",
        confirm_password: "",
      },
      validPassword: true,
      validCPassword: true,
      errors: {
        password: "",
        confirm_password: "",
      },
      blurStatus: {
        password: false,
        confirm_password: false,
      },
    };
  },
  setup() {
    const route = useRoute();
    console.log(route);
    return {
      route,
    };
  },
  methods: {
    onBlur(field) {
      switch (field) {
        case "password":
          this.blurStatus.password = true;
          this.validatePassword();
          break;
        case "confirm_password":
          this.blurStatus.confirm_password = true;
          this.validateConfirmPassword();
          break;
        default:
          break;
      }
    },
    check() {
      this.onBlur("password");
      this.onBlur("confirm_password");
      if (this.route.query.token) {
        if (
          this.validPassword &&
          this.validCPassword &&
          this.validCPassword == this.validPassword
        ) {
          this.errorMsg = "";
          this.isLoading = true;
          this.$http
            .post("auth/update-password", {
              password: this.input.password,
              password_confirmation: this.input.confirm_password,
              token: this.route.query.token,
            })
            .then((res) => {
              this.isLoading = false;
              this.$swal.fire({
                icon: "success",
                title: res.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              if (res.data.role === "vendor") {
                this.$router.push({ name: "VendorSignin" });
              } else {
                this.$router.push({ name: "UserSignIn" });
              }
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
              this.$swal.fire({
                icon: "error",
                title: err.response.data.message,
                timer: 5000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
            });
        } else {
          this.errorMsg = "Invalid email or password";
        }
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Your session seems to be expired",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    },
    validatePassword() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          if (this.input.password.length < 6) {
            this.validPassword = false;
            this.errors.password = "The password must be at least 6 characters";
          } else {
            this.errors.password = "";
            this.validPassword = true;
          }
        } else {
          this.validPassword = false;
          this.errors.password = "Password is required";
          console.log("else");
        }
        console.log(this.validPassword);
      }
    },
    validateConfirmPassword() {
      if (this.blurStatus.confirm_password) {
        if (this.input.confirm_password) {
          if (
            this.input.password &&
            this.input.confirm_password != this.input.password
          ) {
            this.validCPassword = false;
            this.errors.confirm_password =
              "Password and confirm password does not match";
          } else {
            this.validCPassword = true;
            this.errors.confirm_password = "";
          }
        } else {
          this.validCPassword = false;
          this.errors.confirm_password = "Confirm password is required";
        }
        console.log(this.validCPassword);
      }
    },
  },
  components: {
    Loading,
  },
});
</script>

<style scoped>
.input {
  color: #fff;
  border-color: #fff;
}
.input::placeholder {
  color: #fff;
}
</style>