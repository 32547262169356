<template>
  <h1>Please wait.........</h1>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    return {};
  },
  methods: {
    getUserToken() {
      const route = useRoute();
      if (route.query.token) {
        this.verifyToken(route.query.token);
      }
    },
    verifyToken(token) {
      this.$http
        .post("auth/verify/email", { token: token })
        .then((response) => {
          if (response && response.data && response.data.status) {
            console.log(response.data);
            if (response.data.role === "customer") {
              this.$router.push({ name: "UserSignIn" });
              // redirect on User page
            } else {
              this.$router.push({ name: "VendorSignin" });
            }
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          // this.$router.push({ name: "Home" });
        });
    },
  },
  mounted() {
    this.getUserToken();
  },
});
</script>