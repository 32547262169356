<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <user-index class="flex items-center justify-center h-full">
    <Alert class="bg-qgo-primary-bg">
      <div class="flex w-full">
        <div class="flex p-1 align-middle">
          <img
            class="object-scale-down w-12"
            src="../../assets/img/Q&GO_Vert_GreyWhite.png"
          />
        </div>
        <div class="flex flex-col items-start p-1 align-middle">
          <div>
            <span class="text-white"> Get the best experience. </span>
          </div>
          <div>
            <span class="text-qgo-secondary-bg">
              <a href="https://play.google.com/store/apps" target="_blank">
                Tap here to download the app
              </a>
            </span>
          </div>
        </div>
      </div>
    </Alert>
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="
          flex flex-col
          h-full
          place-content-between
          x-sm:w-full
          sm:w-2/3
          lg:w-1/3
          x-sm:px-4
          sm:px-0
          lg:px-0
        "
      >
        <div class="mx-4">
          <div class="flex flex-col justify-center w-full">
            <div
              v-if="scannedQrData.status == 'Ready'"
              class="flex items-center justify-center pt-24"
            >
              <div class="flex flex-col items-center justify-center">
                <p class="text-2xl font-black text-white">YOUR ORDER IS</p>
                <p class="text-2xl font-black text-white">READY FOR</p>
                <p class="text-2xl font-black text-white">COLLECTION!</p>
                <div class="w-32 px-2 py-3 m-auto mb-4">
                  <img
                    class="object-scale-down w-full"
                    src="../../assets/img/Go-Ready.png"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="scannedQrData.status == 'Collected'" class="flex items-center justify-start pt-24">
              <div class="flex flex-col items-start justify-start w-1/2">
                <p class="text-2xl font-black text-white">ORDER</p>
                <p class="text-2xl font-black text-white">COLLECTED!</p>
                <p class="text-2xl font-black text-white">
                  STAY IN TOUCH
                </p>
              </div>
              <div class="w-1/2 px-2 py-3 m-auto mb-4">
                <img
                  class="object-scale-down w-full"
                  src="../../assets/img/Go-Indeterminate.png"
                />
              </div>
            </div>
            <div v-else class="flex items-center justify-start pt-24">
              <div class="flex flex-col items-start justify-start w-1/2">
                <p class="text-2xl font-black text-white">YOUR ORDER</p>
                <p class="text-2xl font-black text-white">IS BEING</p>
                <p class="text-2xl font-black text-white">
                  PREPARED
                </p>
              </div>
              <div class="w-1/2 px-2 py-3 m-auto mb-4">
                <img
                  class="object-scale-down w-full"
                  src="../../assets/img/Go-Indeterminate.png"
                />
              </div>
            </div>
            <div v-if="!scannedQrData.status == 'Ready'" class="pt-2 pb-4">
              <div>
                <div class="flex flex-col items-center justify-center w-full">
                  <p class="text-sm text-qgo-primary-bg">
                    No need to leave this page open.
                  </p>
                  <p class="text-sm text-white">
                    You will still be notified by SMS when your
                  </p>
                  <p class="text-sm text-white">
                    order is ready for collection.
                  </p>
                </div>
              </div>
              <!-- <div v-else>
                <div class="flex flex-col items-center justify-center w-full">
                  <p class="text-sm text-qgo-primary-bg">
                    Please leave this page open.
                  </p>
                  <p class="text-sm text-white">
                    You will be notified below when your
                  </p>
                  <p class="text-sm text-white">
                    order is ready for collection.
                  </p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex w-full large-card-container">
            <div class="flex flex-col justify-center w-full h-auto px-5">
              <div
                class="
                  flex-row
                  w-full
                  h-full
                  px-3
                  pt-3
                  bg-white
                  queue-card-shadow
                "
              >
                <div
                  class="h-full border-2"
                  :class="
                    scannedQrData.status == 'Ready'
                      ? 'border-qgo-primary-bg'
                      : 'border-green-900'
                  "
                >
                  <div class="flex items-center justify-center h-3/5">
                    <div class="flex flex-col justify-center mb-10">
                      <p class="p-1 text-sm text-gray-400 font-extralight">
                        YOUR NUMBER IS
                      </p>
                      <p class="p-1 text-4xl font-black text-black">
                        Q{{ scannedQrData.order_no }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="flex flex-col h-2/5"
                    :class="
                      scannedQrData.status == 'Ready' || scannedQrData.status == 'Collected'
                        ? 'bg-qgo-primary-bg'
                        : 'bg-qgo-secondary-bg'
                    "
                  >
                    <span
                      class="
                        relative
                        justify-center
                        w-32
                        h-32
                        m-auto
                        bg-gray-400
                        rounded-full
                        justify-self-center
                        -top-20
                      "
                    >
                      <img
                        v-if="scannedQrData.vendor_detail.logo"
                        class="inline-block w-32 h-32 rounded-full"
                        :src="scannedQrData.vendor_detail.logo"
                      />
                      <div
                        class="
                          relative
                          flex
                          items-center
                          justify-center
                          my-2
                          -mx-8
                          text-white
                        "
                        :class="
                          scannedQrData.vendor_detail.logo
                            ? '-bottom-0'
                            : '-bottom-32'
                        "
                      >
                        <p
                          v-if="scannedQrData.status == 'Ready'"
                          class="text-lg"
                        >
                          Ready to Collect
                        </p>
                        <p v-else-if="scannedQrData.status == 'Collected'" class="text-lg">Collected</p>
                        <p v-else class="text-lg">Preparing</p>
                        
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import UserIndex from "@/views/User/Index";
import Alert from "@/components/alert/Alert.vue";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      alertOpen: true,
      isLoading: false,
      apiInterval:null,
      privateChannel: null,
      scannedQrData: {
        order_no: null,
        vendor_id: null,
        vendor_detail: { logo: null },
        status: "Preparing",
      },
    };
  },
  components: {
    UserIndex,
    Alert,
    Loading,
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;
    },
    async subscribePusher(queue, store) {
      let event = "";
      if (queue.customer_id) {
        event = queue.customer_id;
      } else {
        let user = await JSON.parse(localStorage.getItem("userId"));
        console.log(user);
        event = user.userId;
      }
      console.log("user-" + event);
      this.channel = this.$globalPusher().subscribe("user-" + event);
      this.channel.bind("queue.ready", function (data) {
        let payload = {
          status: data.status,
        };
        store.commit("queues/setIndividualQueueData", payload);
        localStorage.removeItem("ticketId");
        localStorage.removeItem("userId");
        localStorage.removeItem("vendorLogo");
        localStorage.removeItem("logo");
      });
      this.channel.bind("queue.done", function () {
        let payload = {
          customer_name: "",
          customer_id: "",
          id: 0,
          status: "",
          ticket_name: "Q...",
        };
        store.commit("queues/setIndividualQueueData", payload);
        // router.push({
        //   name: "Home",
        //   query: { vendor_id: data.vendor_id }
        // });
      });
    },
    trialReady() {
      let data = {
        ready: true,
      };
      this.$store.commit("queues/setIndividualQueueData", data);
      // this.$store.commit("queues/setSingleQueueData");
    },
    checkUser() {
      let user = JSON.parse(localStorage.getItem("userId"));
      if (user !== null) {
        if (this.queue.id === 0) {
          this.$store.dispatch("queues/fetchAllQueues", user);
        }
        // window.setTimeout(this.trialReady, 5000);
      } else {
        // this.$router.push({ name: "Home" });
        this.$swal.fire({
          icon: "error",
          title: "Please Scan Again for a new Queue Ticket",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    },
    onPageLoad() {
      this.isLoading = true;
      let orderDetails = localStorage.getItem("scannedQRDetails");
      if (orderDetails) {
        orderDetails = JSON.parse(orderDetails);
        this.scannedQrData = {
          vendor_id: orderDetails.vendor_id,
          order_no: orderDetails.order_id,
          vendor_detail: {
            logo: orderDetails.vendor_logo,
          },
        };
        this.getOrderDetails();
        this.apiInterval = setInterval(() => {
          this.getOrderDetails();
        }, 7000);
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Something went wrong. Error while getting order details",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    },
    getOrderDetails() {
      this.$http
        .get(
          `qr/getdetail?vendor_id=${this.scannedQrData.vendor_id}&order_no=${this.scannedQrData.order_no}`
        )
        .then((response) => {
          this.isLoading = false;
          this.scannedQrData.status = response.data.data.order_status;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
  },
  computed: {
    queue() {
      return this.$store.getters["queues/singleQueueData"];
    },
  },
  mounted() {
    this.onPageLoad();

    // this.checkUser();
    // this.subscribePusher(this.queue, this.$store, this.$router);
  },
  unmounted() {
    if(this.apiInterval) {
      clearInterval(this.apiInterval)
    }
    // this.$globalPusher().unsubscribe("user-" + this.queue.customer_id);
  },
};
</script>
